// routes.js (or equivalent)

import Dashboard from "Components/dashboard";
import Shop from "examples/Icons/Shop";
import Document from "examples/Icons/Document";
import { ArrowUpwardOutlined } from "@mui/icons-material";
import People from "Components/QualifiedEmployees/Employees/Employees";
import { UsergroupDeleteOutlined } from "@ant-design/icons";
import Performance from "Components/PerformanceManagement/PerformanceManagement";
import TrainingAndDevelopment from "Components/TrainingAndDevelopement/Training/TrainingAndDevelopement";
import Complaints from "Components/Complaints/Complaints";
import Compliance from "Components/Compliance/Complaince";
import ChildManager from "./Components/ChildManager/ChildManager";
import EmployeeFinance from "Components/FinancialReport/FinancialReport";
import DocumentsStore from "Components/DocumentsStore/DocumentsStore";
import ReceivedDocuments from "Components/DocumentsStore/ReceivedDoc";
import Settings from "examples/Icons/Settings";
import Assets from "Components/Assets/Assets";
import JobPostingForm from "Components/Hiring/JobPosting/Hiring";
import Report from "Components/Report/Report";

// Example usage in your application
const loginType = localStorage.getItem("userRole");
const apiUrl = process.env.REACT_APP_API_URL;

// Function to fetch permissions
async function fetchPermissions(childManagerId) {
  try {
    const response = await fetch(`${apiUrl}/api/AssociateManager/Get-Permissions/${childManagerId}`);
    if (!response.ok) {
      throw new Error("Permissions fetch failed");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching permissions:", error);
    return null;
  }
}

// Define your initial routes
const initialRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  // { type: "title", title: "HR Management", key: "hr-management" },
  loginType === "HR Manager" && {
    type: "collapse",
    name: "Document Storage",
    key: "document-store",
    route: "/document-store",
    icon: <Shop size="12px" />,
    component: <DocumentsStore />,
    noCollapse: true,
  },
  loginType === "HR Manager" && {
    type: "collapse",
    name: "Associate Manager",
    key: "associate-managers",
    route: "/associate-managers",
    icon: <UsergroupDeleteOutlined size="12px" />,
    component: <ChildManager />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Employees",
    key: "employees",
    route: "/employees",
    icon: <UsergroupDeleteOutlined size="12px" />,
    component: <People />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Hiring",
    key: "hiring",
    route: "/hiring",
    icon: <ArrowUpwardOutlined size="12px" />,
    component: <JobPostingForm />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Report",
    key: "report",
    route: "/report",
    icon: <Document size="12px" />,
    component: <Report />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Performance Management",
    key: "performance",
    route: "/performance",
    icon: <Document size="12px" />,
    component: <Performance />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Training and Development",
    key: "training",
    route: "/training",
    icon: <Document size="12px" />,
    component: <TrainingAndDevelopment />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Complaints",
    key: "complaints",
    route: "/complaints",
    icon: <Document size="12px" />,
    component: <Complaints />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Financial Report",
    key: "financialReport",
    route: "/financialReport",
    icon: <Document size="12px" />,
    component: <EmployeeFinance />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Compliance",
    key: "compliance",
    route: "/compliance",
    icon: <Document size="12px" />,
    component: <Compliance />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Assets",
    key: "assets",
    route: "/assets",
    icon: <Settings size="12px" />,
    component: <Assets />,
    noCollapse: true,
  },
  loginType === "HR Manager" && {
    type: "collapse",
    name: "Received Documents",
    key: "received-Documents",
    route: "/received-Documents",
    icon: <Shop size="12px" />,
    component: <ReceivedDocuments />,
    noCollapse: true,
  },
].filter(Boolean);

// Function to wrap long names with white-space
function wrapNameIfLong(name) {
  if (name && name.length > 16) {
    return name.substring(0, 16) + "...";
  }
  return name;
}

// Update names in routes
const routes = initialRoutes.map((route) => ({
  ...route,
  name: wrapNameIfLong(route.name),
}));

export async function updateRoutes(childManagerId) {
  if (loginType === "HR Manager") {
    return initialRoutes.map((route) => ({
      ...route,
      name: wrapNameIfLong(route.name),
    }));
  } else if (childManagerId) {
    const permissions = await fetchPermissions(childManagerId);
    if (permissions) {
      const filteredRoutes = initialRoutes.filter((route) => {
        if (route.key && permissions[route.key.toLowerCase()] === false) {
          return false; // Filter out routes with false permissions
        }
        return true;
      });
      routes.splice(
        0,
        routes.length,
        ...filteredRoutes.map((route) => ({
          ...route,
          name: wrapNameIfLong(route.name),
        }))
      );
    }
    return routes;
  }
}

// const childManagerId = 1;
const childManagerId = localStorage.getItem("childManagerId");
updateRoutes(childManagerId);

// Exporting the function to update routes
export default routes;
