import React, { useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Bar } from "react-chartjs-2";
import { Button, Card, Table } from "antd";
import WorkingHoursService from "Components/Report/ReportAttendance/WorkingHoursService";
import Footer from "Components/authentication/components/Footer";
import { useNavigate } from "react-router-dom";

function ReportsAttendance() {
  const [currentMonth, setCurrentMonth] = useState("January");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const navigate = useNavigate();
  const {
    departments,
    showFields,
    years,
    aggregatedData,
    handleViewMonthlyReport,
    columns,
    options,
    barChartData,
    handleDownloadReport,
    handleDepartmentChange,
    formData,
    formDataError,
    isLoading,
    handleChange,
    handleAddWorkingHours,
    handleDefinePolicy,
    selectedDepartment,
    showDefinePolicy,
  } = WorkingHoursService(currentMonth, currentYear);
  const back = () => {
    navigate(-1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        title="Attendance"
        className="card-professional"
        extra={
          <Button type="default" onClick={back}>
            Back
          </Button>
        }
      >
        {showFields && showDefinePolicy && (
          <div className="row">
            <div className="col-md-12 mb-2">
              <Button
                onClick={handleViewMonthlyReport}
                type="primary"
                style={{ marginRight: "8px" }}
              >
                View Monthly Report
              </Button>
              <Button onClick={handleDefinePolicy} type="primary">
                Define Policy
              </Button>
            </div>

            <div className="col-md-12">
              <Bar type="bar" data={barChartData} options={options} />
            </div>
          </div>
        )}
        {!showFields && (
          <div className="row mb-3">
            <label className="mb-3" style={{ textAlign: "left" }}>
              <i className="fa fa-calendar" aria-hidden="true"></i>&nbsp;Monthly Attendance Reports
            </label>
            <div className="row">
              <div className="col-md-2 mb-3">
                <select
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                  value={currentMonth}
                  onChange={(e) => setCurrentMonth(e.target.value)}
                >
                  <option disabled>Months</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
              </div>
              <div className="col-md-2 mb-3">
                <select
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                  value={currentYear}
                  onChange={(e) => setCurrentYear(e.target.value)}
                >
                  <option disabled>Years</option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3 mb-3">
                <select
                  className="form-select form-select-sm"
                  value={selectedDepartment}
                  onChange={handleDepartmentChange}
                >
                  <option value="All Departments">All Departments</option>
                  {departments.map((department) => (
                    <option key={department} value={department}>
                      {department}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-5 mb-3">
                <button
                  className="btn btn-outline-info btn-sm"
                  style={{ float: "inline-end", marginTop: "-5px" }}
                  onClick={handleDownloadReport}
                >
                  Download Reports
                </button>
              </div>
            </div>
            <Table
              className="table-responsive"
              bordered
              dataSource={aggregatedData}
              columns={columns}
              pagination={{ pageSize: 10 }}
            />
          </div>
        )}
        {!showDefinePolicy && (
          <>
            <div className="row">
              <div className="col-md-5">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Working Hours From*
                  </label>
                  <input
                    type="time"
                    name="workingHoursFrom"
                    value={formData.workingHoursFrom}
                    onChange={handleChange}
                    className={`form-control ${
                      formDataError.workingHoursFromError ? "is-invalid" : ""
                    }`}
                  />
                  {formDataError.workingHoursFromError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.workingHoursFromError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Working Hours To*
                  </label>
                  <input
                    type="time"
                    name="workingHoursTo"
                    value={formData.workingHoursTo}
                    onChange={handleChange}
                    className={`form-control ${
                      formDataError.workingHoursToError ? "is-invalid" : ""
                    }`}
                  />
                  {formDataError.workingHoursToError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.workingHoursToError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Present *
                  </label>
                  <input
                    type="time"
                    name="presentTime"
                    value={formData.presentTime}
                    onChange={handleChange}
                    className={`form-control ${formDataError.presentTimeError ? "is-invalid" : ""}`}
                  />
                  {formDataError.presentTimeError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.presentTimeError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Late Arrival *
                  </label>
                  <input
                    type="time"
                    name="lateArrivalTime"
                    value={formData.lateArrivalTime}
                    onChange={handleChange}
                    className={`form-control ${
                      formDataError.lateArrivalTimeError ? "is-invalid" : ""
                    }`}
                  />
                  {formDataError.lateArrivalTimeError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.lateArrivalTimeError}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-10">
              <Button
                type="primary"
                style={{ float: "inline-end" }}
                onClick={handleAddWorkingHours}
                disabled={isLoading}
              >
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            </div>
          </>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default ReportsAttendance;
