// HiringApiService.js
import axios from "axios";

// Base URL configuration
const apiUrl = process.env.REACT_APP_API_URL;

// Fetch user by email
export const getUserNameByEmail = async (email, loginType) => {
  const endpoint = loginType === "HR" ? "Auth/GetUserByEmail" : "ChildManager/Get-Associate-Manager";
  const response = await axios.get(`${apiUrl}/api/${endpoint}/${encodeURIComponent(email)}`);
  return response.data;
};

// Fetch all child managers
export const getAllChildManagers = async () => {
  const response = await axios.get(`${apiUrl}/api/AssociateManager/GetAllChildManagers`);
  return response.data;
};

// Fetch job postings
export const getJobPostings = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/HiringProcess/Getposting-Detail`);
    return response.data;
  } catch (error) {
    console.error("Error fetching job postings from API:", error);
    throw error;
  }
};

// Add a new job posting
export const addJobPosting = async (jobData) => {
  const response = await axios.post(`${apiUrl}/api/HiringProcess/AddPosting-Job`, jobData);
  return response.data;
};

// Update an existing job posting
export const updateJobPosting = async (jobId, jobData) => {
  const response = await axios.put(`${apiUrl}/api/HiringProcess/EditPosting-Job/${jobId}`, jobData);
  return response.data;
};
