import { message as antdMessage, Menu, Form } from "antd";
import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./announcement.scss";
import { useNavigate } from "react-router-dom";

function Service() {
  const [form] = Form.useForm(); // Create form instance
  const [announcements, setAnnouncements] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const fetchAnnouncements = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/Announcement/GetAll`);
      setAnnouncements(response.data);
    } catch (error) {
      antdMessage.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddAnnouncement = () => {
    setIsAdding(true);
    setEditMode(false);
    setSelectedAnnouncement(null);
    form.resetFields(); // Reset form fields when adding
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleCancel = () => {
    setIsAdding(false);
    setEditMode(false);
    setSelectedAnnouncement(null);
    form.resetFields();
  };
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const response = await axios.post(`${apiUrl}/api/Announcement/Add`, {
        announcement: values.announcement,
        message: values.announcementMessage,
      });

      antdMessage.success('Announcement Created Successfully!');
      setIsAdding(false);
      fetchAnnouncements();
    } catch (error) {
        antdMessage.error(error.response.data);
    }
  };

  const handleEditAnnouncement = async () => {
    try {
      const values = await form.validateFields();
      const response = await axios.put(
        `${apiUrl}/api/Announcement/Edit/${selectedAnnouncement.announcementId}`,
        {
          announcement: values.announcement,
          message: values.announcementMessage,
        }
      );

      antdMessage.success(response.data || "Announcement updated successfully");
      setEditMode(false);
      form.resetFields();
      setSelectedAnnouncement(null);
      fetchAnnouncements();
    } catch (error) {
      if (error.response) {
        antdMessage.error(error.response.data || "Failed to update announcement");
      } else {
        antdMessage.error("An unexpected error occurred: " + error.message);
      }
    }
  };

  const handleMenuClick = async (action, ann) => {
    switch (action) {
      case "view":
        navigate(`/announcement-detail/${ann.announcementId}`, { state: { announcement: ann } });
        break;
      case "edit":
        setEditMode(true);
        setSelectedAnnouncement(ann);
        form.setFieldsValue({
          announcement: ann.announcement,
          announcementMessage: ann.message,
        });
        break;
      case "delete":
        const confirmDelete = window.confirm("Are you sure you want to delete this announcement?");
        if (confirmDelete) {
          try {
            await axios.delete(`${apiUrl}/api/Announcement/Delete/${ann.announcementId}`);
            antdMessage.success("Announcement deleted successfully");
            fetchAnnouncements();
          } catch (error) {
            antdMessage.error("Failed to delete announcement: " + error.message);
          }
        }
        break;
      default:
        break;
    }
  };

  const menu = (ann) => (
    <Menu>
      <Menu.Item key="view" onClick={() => handleMenuClick("view", ann)}>
        View Announcement
      </Menu.Item>
      <Menu.Item key="edit" onClick={() => handleMenuClick("edit", ann)}>
        Edit
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => handleMenuClick("delete", ann)}>
        Delete
      </Menu.Item>
    </Menu>
  );
  return {
    isAdding,
    handleCancel,
    editMode,
    loading,
    announcements,
    menu,
    handleEditAnnouncement,
    handleSubmit,
    handleAddAnnouncement,
    truncateText,
    form,
  };
}
export default Service;
