import { useState, useEffect } from "react";
import { Button, Card, Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Link } from "react-router-dom";
import axios from "axios";
import SoftBox from "StyleFeatures/SoftBox";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Footer from "Components/authentication/components/Footer";

function Complaints() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("All Departments");
  const [dataSource, setDataSource] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const response = await axios.get(`${apiUrl}/api/Complaint/Get-Complaint-Detail`);
      const responseData = response.data;

      if (!responseData || !responseData.complaintDetail) {
        throw new Error("Invalid API response structure");
      }

      const complaintDetail = responseData.complaintDetail;

      // Generate rows based on the fetched data
      const updatedRows = complaintDetail.map((info) => ({
        key: info.complaintId.toString(),
        ComplaintId: info.complaintId,
        ComplaintName: info.complaintName,
        Email: info.email,
        Phone: info.phoneNo,
        ComplaintAgainst: info.complaintAgainst,
        Department: info.departments,
        Status:
          info.status === 0
            ? "Pending"
            : info.status === 1
            ? "Resolved"
            : info.status === 2
            ? "Rejected"
            : "Unknown",
        Action: (
          <span style={{ display: "flex", alignItems: "center" }}>
            <Link to={`/complaintDetail/${info.complaintId}/${info.email}`}>
              <EyeOutlined className="eyeOutlined" />
            </Link>
          </span>
        ),
      }));

      // Update dataSource state
      setDataSource(updatedRows);
      filterData(selectedDepartment, updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  const filterData = (department, data) => {
    if (department === "All Departments") {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item) => item.Department === department);
      setFilteredData(filtered);
    }
  };

  const handleDepartmentChange = (e) => {
    const selectedDept = e.target.value;
    setSelectedDepartment(selectedDept);
    filterData(selectedDept, dataSource);
  };

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/AttendanceReport/Get-All-Departments`);
        const departmentNames = response.data.map((dept) => dept.department);
        setDepartments(departmentNames);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, [apiUrl]);

  const handleDownloadReport = () => {
    const data = selectedDepartment === "All Departments" ? dataSource : filteredData;

    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Complaint Report", 14, 22);

    const lineY = 28;
    const lineWidth = 0.5;
    const lineColor = [192, 192, 192];
    doc.setLineWidth(lineWidth);
    doc.setDrawColor(...lineColor);
    doc.line(14, lineY, 200, lineY);

    const marginTop = 3;
    const marginTopEOM = 2;
    doc.setFontSize(12);
    doc.text("Employee: All - All employees selected", 14, 32 + marginTop);

    // Get the current date
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();

    doc.text(`Report Date: ${formattedDate}`, 14, 42 + marginTopEOM);
    doc.text("Exceptions: No Deletions", 14, 52);
    doc.text(`Department: ${selectedDepartment}`, 14, 59);

    const headers = [
      [
        "Complaint ID",
        "Complaint Name",
        "Email",
        "Phone",
        "Complaint Against",
        "Department",
        "Status",
      ],
    ];
    const dataRows = data.map((complaint) => [
      complaint.ComplaintId,
      complaint.ComplaintName,
      complaint.Email,
      complaint.Phone,
      complaint.ComplaintAgainst,
      complaint.Department,
      complaint.Status,
    ]);

    doc.autoTable({
      startY: 62,
      head: headers,
      body: dataRows,
      headerStyles: {
        fillColor: [192, 192, 192],
        fontSize: 8,
      },
      styles: {
        fontSize: 8,
      },
    });

    doc.save(`complaint_report_${selectedDepartment}.pdf`);
  };

  const columns = [
    {
      title: "Complaint ID",
      dataIndex: "ComplaintId",
      key: "ComplaintId",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: "Complaint Name",
      dataIndex: "ComplaintName",
      key: "ComplaintName",
    },
    {
      title: "Complaint Against",
      dataIndex: "ComplaintAgainst",
      key: "ComplaintAgainst",
    },
    {
      title: "Department",
      dataIndex: "Department",
      key: "Department",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
    },
  ];

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card title="Complaints" className="card-professional">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <SoftBox color="text" style={{ marginRight: "20px" }}>
                <div style={{ display: "inline-block" }}>
                  <Button
                    type="primary"
                    onClick={handleDownloadReport}
                  >
                    <i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download Complaint
                    Reports
                  </Button>
                </div>
              </SoftBox>
              <div className="mb-3" style={{ display: "inline-block" }}>
                <select
                  className="form-select form-select-sm"
                  value={selectedDepartment}
                  onChange={handleDepartmentChange}
                >
                  <option value="All Departments">All Departments</option>
                  {departments.map((department) => (
                    <option key={department} value={department}>
                      {department}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <Table
                className="table-responsive"
                bordered
                loading={loading}
                dataSource={filteredData}
                columns={columns}
                pagination={{ pageSize: 10 }}
              />
            </div>
          </Card>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Complaints;
