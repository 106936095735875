import React, { useState, useEffect } from "react";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import { Button, Card, message, Table, Upload } from "antd";
import "./Document.scss";
import axios from "axios";
import { FileOpenOutlined } from "@mui/icons-material";
import { Space } from "antd";
import { UploadOutlined } from "@ant-design/icons";

function Documents() {
  const [employeeContract, setEmployeeContract] = useState(null);
  const [companyDoc, setCompanyDoc] = useState(null);
  const [fileUploads, setFileUploads] = useState({}); // Initialize fileUploads state
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState("Submit Documents");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    showContractData();
    companyDocuments();
  }, []);

  const showContractData = () => {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        axios
          .get(`${apiUrl}/api/EmployeeInterview/GetReceiveContractByEmail/${loginEmail}`)
          .then((response) => {
            setEmployeeContract(response.data);
          })
          .catch((error) => {
            console.error("Error fetching contract data:", error);
          });
      }
    } catch (error) {
      console.error("Error fetching contract data:", error);
    }
  };

  const companyDocuments = () => {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        axios
          .get(`${apiUrl}/api/EmployeeDocuments/Get-Documents/${loginEmail}`)
          .then((response) => {
            setCompanyDoc(response.data);
            // Check the status
            if (response.data.status === "Documents Received") {
              setIsButtonDisabled(true);
              setSubmitButtonText("Your Document Sent");
            }
          })
          .catch((error) => {
            console.error("Error fetching contract data:", error);
          });
      }
    } catch (error) {
      console.error("Error fetching contract data:", error);
    }
  };

  const handlePdfPreview = async (fileUrl) => {
    try {
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };

  const handleFileChange = (file, docType) => {
    const maxSize = docType === "hrpolicy" ? 40 * 1024 * 1024 : 2 * 1024 * 1024; // 100 MB for hrpolicy, 2 MB for others

    if (file) {
      if (file.size > maxSize) {
        // Display validation error using message
        message.error(`File size exceeds the limit of ${maxSize / (1024 * 1024)} MB.`);
        return;
      }

      setFileUploads((prev) => ({ ...prev, [docType]: file }));
    } else {
      setFileUploads((prev) => ({ ...prev, [docType]: null }));
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    // Existing data ko reuse karna
    const employeeData = {
      department: employeeContract.department, // Existing department
      email: employeeContract.email, // Existing email
      jobtitle: employeeContract.jobtitle, // Existing job title
      name: employeeContract.name, // Existing name
      status: employeeContract.status, // Existing status
    };

    // FormData mein sirf files ko add karein
    Object.keys(fileUploads).forEach((key) => {
      if (fileUploads[key]) {
        formData.append(key, fileUploads[key]);
      }
    });

    // Existing data ko formData mein add karein
    for (const [key, value] of Object.entries(employeeData)) {
      formData.append(key, value);
    }

    const employeeId = employeeContract?.employeeId;
    if (employeeId) {
      try {
        setLoading(true);
        await axios.put(
          `${apiUrl}/api/EmployeeDocuments/Update-Employee-Documents/${employeeId}`,
          formData
        );
        message.success("Documents updated successfully");
        setFileUploads({});
        companyDocuments();
      } catch (error) {
        console.error("Error updating documents:", error);
        message.error("Files Required");
      } finally {
        setLoading(false);
      }
    }
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Download/Preview",
      key: "download",
      render: (record) => (
        <Space size="middle">
          <FileOpenOutlined
            onClick={() => handlePdfPreview(`${apiUrl}/${record.file}`)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
  ];

  const CompanyDoccolumns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Download/Preview",
      key: "download",
      render: (record) => (
        <Space size="middle">
          <FileOpenOutlined
            onClick={() => handlePdfPreview(`${apiUrl}/${record.file}`)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
    {
      title: "Upload New Document",
      key: "upload",
      render: (record) => (
        <Upload
          beforeUpload={(file) => {
            handleFileChange(file, record.key);
            return false; // Prevent auto upload
          }}
          accept=".pdf,.doc,.docx"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Choose File</Button>
        </Upload>
      ),
    },
  ];

  const documentData = [
    employeeContract?.ndafile && {
      key: "ndafile",
      name: "NDA File",
      file: employeeContract.ndafile,
    },
    employeeContract?.ncafile && {
      key: "ncafile",
      name: "NCA File",
      file: employeeContract.ncafile,
    },
    employeeContract?.employeeAgreement && {
      key: "employeeAgreement",
      name: "Employee Agreement",
      file: employeeContract.employeeAgreement,
    },
    employeeContract?.i_nine && {
      key: "i_nine",
      name: "I-9 File",
      file: employeeContract.i_nine,
    },
    employeeContract?.w_four && {
      key: "w_four",
      name: "W-9 File",
      file: employeeContract.w_four,
    },
    employeeContract?.offerLetter && {
      key: "offerLetter",
      name: "Offer Letter",
      file: employeeContract.offerLetter,
    },
  ].filter(Boolean); // Filter out null/undefined values

  const CompanyDocx = [
    companyDoc?.i_nine && {
      key: "i_nine",
      name: "I-9 File",
      file: companyDoc.i_nine,
    },
    companyDoc?.w_four && {
      key: "w_four",
      name: "W-4 File",
      file: companyDoc.w_four,
    },
    companyDoc?.hrpolicy && {
      key: "hrpolicy",
      name: "HR Policy",
      file: companyDoc.hrpolicy,
    },
  ].filter(Boolean); // Filter out null/undefined values

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card title="Documents" className="card-professional">
            <div className="row mb-3">
              {documentData.length > 0 ? (
                <Table dataSource={documentData} pagination={false} columns={columns} />
              ) : (
                <p>Sorry, you don’t have any documents.</p>
              )}
            </div>
          </Card>
          <br />
          <Card title="Company Documents" className="card-professional">
            <div className="row mb-3">
              <Table dataSource={CompanyDocx} pagination={false} columns={CompanyDoccolumns} />
            </div>
            <Button
              type="primary"
              onClick={handleSubmit}
              style={{ marginTop: 16 }}
              disabled={loading || isButtonDisabled} // Disable button during loading or if documents are already sent
            >
              {loading ? (
                <>
                  <span>Submitting...</span>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginLeft: "8px" }}
                  ></span>
                </>
              ) : (
                submitButtonText
              )}
            </Button>
          </Card>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Documents;
