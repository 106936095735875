import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./complaintDetail.scss";
import { useNavigate } from "react-router-dom";
import Footer from "Components/authentication/components/Footer";
import { Button, Card } from "antd";

function ViweCorrectiveDetail() {
  const { complaintId } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [complaintDetail, setComplaintDetail] = useState(null);
  const backtoComplaint = () => {
    navigate("/complaints");
  };

  useEffect(() => {
    async function fetchComplaintDetail() {
      try {
        const response = await axios.get(
          `${apiUrl}/api/CorrectiveComplaintAction/Get-Complaint/${complaintId}`
        );
        setComplaintDetail(response.data);
      } catch (error) {
        console.error("Error fetching employee detail:", error);
      }
    }
    fetchComplaintDetail();
  }, [complaintId]);

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <div className="body flex-grow-1 px-3">
          <br></br>
          <br></br>
          {complaintDetail && (
            <>
              <Card
                className="card-professional"
                title="Corrective Complaint Details"
                extra={
                  <Button type="default" onClick={backtoComplaint}>
                    Back
                  </Button>
                }
              >
                <div className="complaintAction mb-2">
                  <h6 className="h6-complaintDetail">Associate Name </h6>
                  <span className="h6-complaintDetail">{complaintDetail.assosiateName}</span>
                </div>
                <div className="complaintAction mb-2">
                  <h6 className="h6-complaintDetail"> Email </h6>
                  <span className="h6-complaintDetail">{complaintDetail.email}</span>
                </div>
                <div className="complaintAction mb-2">
                  <h6 className="h6-complaintDetail">Department name </h6>
                  <span className="h6-complaintDetail">{complaintDetail.departmentName}</span>
                </div>
                <div className="complaintAction mb-2">
                  <h6 className="h6-complaintDetail">Date Of Incident </h6>
                  <span className="h6-complaintDetail">{complaintDetail.dateOfIncident}</span>
                </div>
                <div className="complaintAction mb-2">
                  <h6 className="h6-complaintDetail">Type of Warning</h6>
                  <span className="h6-complaintDetail">{complaintDetail.typeOfWarning}</span>
                </div>
                <div className="complaintAction mb-2">
                  <h6 className="h6-complaintDetail">Complaint Detail of Incident</h6>
                  <div className="incident-detail">
                    <span className="h6-complaintDetail">{complaintDetail.detailOfIncident}</span>
                  </div>
                </div>

                <div className="complaintAction mb-2">
                  <h6 className="h6-complaintDetail">Action Plan(s) for Improvement:</h6>
                  <span className="h6-complaintDetail">{complaintDetail.improvement}</span>
                </div>
                <div className="complaintAction mb-2">
                  <h6 className="h6-complaintDetail">Associate’s Comments:</h6>
                  <span className="h6-complaintDetail">{complaintDetail.associateComments}</span>
                </div>
                <div className="complaintAction mb-2">
                  <h6 className="h6-complaintDetail">Summary of counseling session:</h6>
                  <span className="h6-complaintDetail">{complaintDetail.summarySession}</span>
                </div>
              </Card>
            </>
          )}
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default ViweCorrectiveDetail;
