import { useState, useEffect } from "react";
import axios from "axios";

function Service() {
  const personalId = localStorage.getItem("personalId");
  const email = localStorage.getItem("loginEmail");
  const [activeIndex, setActiveIndex] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [datahardwareSource, setDatahardwareSource] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    fetchData();
    fetchHardwareData();
  }, []);

  async function fetchData() {
    try {
      if (!personalId) {
        console.error("No personalId found in localStorage");
        return;
      }

      // Fetch software assets by personalId
      const response = await axios.get(
        `${apiUrl}/api/SoftwareAssets/GetPersonalInfo/${personalId}`
      );

      const userData = response.data;

      // Generate rows based on the fetched assets data
      const updatedRows = userData.assets.map((asset) => {
        function formatDateTime(dateTimeString) {
          if (!dateTimeString) return "N/A";

          const options = {
            year: "numeric",
            month: "long",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          };

          return new Date(dateTimeString).toLocaleString("en-US", options);
        }

        return {
          key: asset.softwareAssetsId.toString(),
          Category: asset.category || "N/A",
          Description: asset.description || "N/A",
          Provider: asset.provider || "N/A",
          Licensekey: asset.licenseKey || "N/A",
          SubscriptionDate: <span>{formatDateTime(asset.subscriptionDate)}</span>,
          ExpiryDate: <span>{formatDateTime(asset.expiryDate)}</span>,
          AssetsCost: asset.assetsCost || 0,
          Notes: asset.notes || "N/A",
        };
      });
      setDataSource(updatedRows);
    } catch (error) {
      console.error("Error fetching software data:", error);
    }
  }

  async function fetchHardwareData() {
    try {
      if (!personalId) {
        console.error("No personalId found in localStorage");
        return;
      }

      // Fetch hardware assets by personalId
      const response = await axios.get(
        `${apiUrl}/api/HardwareAssets/GetPersonalInfo/${personalId}`
      );

      const userData = response.data;

      // Generate rows based on the fetched assets data
      const updatedRows = userData.assets.map((asset) => {
        function formatDateTime(dateTimeString) {
          if (!dateTimeString) return "N/A";

          const options = {
            year: "numeric",
            month: "long",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          };

          return new Date(dateTimeString).toLocaleString("en-US", options);
        }

        return {
          key: asset.hardwareAssetsId.toString(),
          Category: asset.category || "N/A",
          Description: asset.description || "N/A",
          Serial: asset.serial || "N/A",
          DateLoaned: <span>{formatDateTime(asset.dateLoaned)}</span>,
          DateReturned: <span>{formatDateTime(asset.dateReturned)}</span>,
          AssetsCost: asset.assetsCost || 0,
          Notes: asset.notes || "N/A",
        };
      });
      setDatahardwareSource(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
      // You might want to add error handling UI here
    }
  }

  const hardwarecol = [
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Serial",
      dataIndex: "Serial",
      key: "Serial",
    },
    {
      title: "Date Loaned",
      dataIndex: "DateLoaned",
      key: "DateLoaned",
    },
    {
      title: "Date Returned",
      dataIndex: "DateReturned",
      key: "DateReturned",
    },
    {
      title: "Assets Cost",
      dataIndex: "AssetsCost",
      key: "AssetsCost",
    },
    {
      title: "Notes",
      dataIndex: "Notes",
      key: "Notes",
    },
  ];

  const softwarecol = [
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Provider",
      dataIndex: "Provider",
      key: "Provider",
    },
    {
      title: "License key",
      dataIndex: "Licensekey",
      key: "Licensekey",
    },
    {
      title: "Subscription Date",
      dataIndex: "SubscriptionDate",
      key: "SubscriptionDate",
    },
    {
      title: "Expiry Date",
      dataIndex: "ExpiryDate",
      key: "ExpiryDate",
    },
    {
      title: "Assets Cost",
      dataIndex: "AssetsCost",
      key: "AssetsCost",
    },
    {
      title: "Notes",
      dataIndex: "Notes",
      key: "Notes",
    },
  ];
  return { softwarecol, hardwarecol, dataSource, datahardwareSource, toggleAccordion, activeIndex };
}

export default Service;
