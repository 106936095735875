import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const ComplainceService = {
  // Fetch all compliance records
  fetchComplainces: async () => {
    try {
      const response = await axios.get(`${API_URL}/api/Compliance/Get-Compliance`);
      return response.data || [];
    } catch (error) {
      console.error("Error fetching compliance data:", error);
      throw error;
    }
  },

  // Add a new compliance record
  addComplaince: async (formData) => {
    try {
      await axios.post(`${API_URL}/api/Compliance/Add-Compliance`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error("Error adding compliance:", error);
      throw error;
    }
  },
};

export default ComplainceService;
