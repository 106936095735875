import { useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Service() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      password: "",
    });
  
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
  
    const handleCancel = () => {
      navigate("/associate-managers");
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
  
      setFormData({
        ...formData,
        [name]: value,
      });
  
      if (value.trim() !== "" && errors[name]) {
        setErrors({
          ...errors,
          [name]: undefined,
        });
      } else if (value.trim() === "") {
        setErrors({
          ...errors,
          [name]: `${name.charAt(0).toUpperCase() + name.slice(1)} is required`,
        });
      }
    };
  
    const validateForm = () => {
      const newErrors = {};
      if (!formData.name) newErrors.name = "Name is required";
      if (!formData.email) newErrors.email = "Email is required";
      if (!formData.password) {
        newErrors.password = "Password is required";
      } else {
        if (formData.password.length < 8) {
          newErrors.password = "Password must be at least 8 characters";
        } else if (
          !/(?=.*[a-z])/.test(formData.password) ||
          !/(?=.*[A-Z])/.test(formData.password) ||
          !/(?=.*\d)/.test(formData.password) ||
          !/(?=.*[@$!%*?&])/.test(formData.password)
        ) {
          newErrors.password =
            "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character";
        }
      }
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (validateForm()) {
        try {
          setIsLoading(true);
          await axios.post(`${apiUrl}/api/AssociateManager/Add-Associate-Manager`, formData);
          message.success("Child Manager Account Created successfully");
          navigate("/associate-managers");
        } catch (error) {
          message.error(error.response.data);
        } finally {
          setIsLoading(false);
        }
      }
    };

  return {
    isLoading,
    handleCancel,
    handleChange,
    handleSubmit,
    errors,
    formData
  };
}

export default Service;
