import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "Components/authentication/components/Footer";
import { Button, Card } from "antd";
import "./complaintDetail.scss";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function ComplaintDetail() {
  const { complaintId, email } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const handleAddCorrectiveAction = () => {
    navigate(`/corrective-action-form/${complaintId}/${email}`);
  };

  const handleViewCorrectiveAction = () => {
    navigate(`/view-corrective-action-form/${complaintId}`);
  };

  const [complaintDetail, setComplaintDetail] = useState(null);

  useEffect(() => {
    async function fetchComplaintDetail() {
      try {
        const response = await axios.get(`${apiUrl}/api/Complaint/Get-Complaints/${email}`);
        setComplaintDetail(response.data);
      } catch (error) {
        console.error("Error fetching employee detail:", error);
      }
    }
    fetchComplaintDetail();
  }, [complaintId]);

  const getStatusString = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Resolved";
      case 2:
        return "Rejected";
      default:
        return "Unknown";
    }
  };

  function formatDateTime(dateTimeString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateTimeString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = date.getMinutes();
    return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />
      <Card title="Complaint Detail" className="card-professional">
        {complaintDetail && (
          <>
            <div className="complaintAction mb-2">
              <h6 className="h6-complaintDetail">First Name </h6>
              <span className="h6-complaintDetail">{complaintDetail.complaintInfo.firstName}</span>
            </div>
            <div className="complaintAction mb-2">
              <h6 className="h6-complaintDetail">Last Name </h6>
              <span className="h6-complaintDetail">{complaintDetail.complaintInfo.lastName}</span>
            </div>
            <div className="complaintAction mb-2">
              <h6 className="h6-complaintDetail"> Email </h6>
              <span className="h6-complaintDetail">{complaintDetail.complaintInfo.email}</span>
            </div>
            <div className="complaintAction mb-2">
              <h6 className="h6-complaintDetail">Phone No </h6>
              <span className="h6-complaintDetail">{complaintDetail.complaintInfo.phoneNo}</span>
            </div>
            <div className="complaintAction mb-2">
              <h6 className="h6-complaintDetail">Complaint Name </h6>
              <span className="h6-complaintDetail">
                {complaintDetail.complaintInfo.complaintName}
              </span>
            </div>
            <div className="complaintAction mb-2">
              <h6 className="h6-complaintDetail">Complaint Against</h6>
              <span className="h6-complaintDetail">
                {complaintDetail.complaintInfo.complaintAgainst}
              </span>
            </div>
            <div className="complaintAction mb-2">
              <h6 className="h6-complaintDetail">Complaint Detail of Incident</h6>
              <div className="incident-detail">
                <span className="h6-complaintDetail">
                  {complaintDetail.complaintInfo.detailofIncident}
                </span>
              </div>
            </div>

            <div className="complaintAction mb-2">
              <h6 className="h6-complaintDetail">Status</h6>
              <span className="h6-complaintDetail">
                {getStatusString(complaintDetail.complaintInfo.status)}
              </span>
            </div>
            <div className="complaintAction mb-2">
              <h6 className="h6-complaintDetail">Requested Date</h6>
              <span className="h6-complaintDetail">
                {formatDateTime(complaintDetail.complaintInfo.complaintDate)}
              </span>
            </div>
            <div className="col-md-12">
              {complaintDetail.complaintInfo.status === 0 ? (
                <Button
                  type="primary"
                  style={{ float: "inline-end" }}
                  onClick={handleAddCorrectiveAction}
                >
                  Add Corrective Action
                </Button>
              ) : (
                <Button
                  type="primary"
                  style={{ float: "inline-end" }}
                  onClick={handleViewCorrectiveAction}
                >
                  View Corrective Action
                </Button>
              )}
            </div>
          </>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default ComplaintDetail;
