import React, { useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import DashboardLayout from "../../../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../LayoutNavbar/Navbars/DashboardNavbar";
import "./announcement.scss";
import { Button, Card } from "antd";

function EmployeeAnnouncementDetail() {
  const location = useLocation();
  const { announcement } = location.state;
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/employeedashboard");
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card
            className="card-professional"
            title="Announcement Detail"
            extra={
              <Button type="primary" onClick={handleBack}>
                Back to Dashboard
              </Button>
            }
          >
            <div className="row mb-3">
              <strong className="subject-detail">{announcement.announcement}</strong>
              <br />
              <p className="message-detail">{announcement.message}</p>
            </div>
          </Card>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EmployeeAnnouncementDetail;
